import { debounce } from 'underscore'; // https://underscorejs.org

jQuery(function ($) {
    'use strict';

    /****************************************************************/
    /******************** MAIN MENU FUNCTIONALITY *******************/
    /****************************************************************/
    const $htmlBody             = $('html, body');
    const $body                 = $('body');
    const mobileBreakPoint      = 991; // make sure sass var is set the same
    const $mobileTrigger        = $('header .mobile-trigger-wrap .hamburger');
    const $dropLinks            = $('header .col-mm-triggers li.drop-menu');
    const $megaMenu             = $('header #mega-menu');
    const $mmItems              = $megaMenu.find('.mm-item');
    const $mobMenu              = $('header #mobile-menu');
    const $mobSubTriggers       = $mobMenu.find('.mobile-drop-menu .mm-item .drop-trigger');
    const $searchTrigger        = $('header .top-links > .search-trigger');
    let navState                = null;
    let navTimerClose           = null;

    // check/set nav state (mobile/desktop) on load and window resize
    function setNavState() {

        if( $(window).width() > mobileBreakPoint ) {

            navState = 'desktop';
            $mobileTrigger.removeClass('is-active');
            $body.removeClass('sub-nav-open');
            $htmlBody.removeClass('mobile-nav-open');
            $mobMenu.css('max-height', '0');
            $mobSubTriggers.removeClass('active').next().css('max-height', '0');

        }  else {

            navState = 'mobile';

        }

        if( $(window).width() > 575 ) {
            // fixes for footer menu mobile breakpoint
            $footerQLSubTriggers.removeClass('active').next().css({
                'max-height': '',
                'padding-bottom': ''
            });

        }
    }
    // store debounced version to use when window is resized (bottom of file)
    const dbc_SetNavState = debounce(setNavState, 100);


    // DESKTOP MENU FUNCTIONS
    function mmCloser() {
        if(navState == 'desktop') {

            navTimerClose = setTimeout(function() {
                $htmlBody.removeClass('megamenu-open');
                $dropLinks.removeClass('active');
                $megaMenu.find('.mm-innerwrap').css('height', 0);
            }, 400);

        }
    }

    $dropLinks.on('mouseenter', function(e) {

        if(navState == 'desktop') {
            clearTimeout(navTimerClose);

            let currSlug = $(e.currentTarget).data('drop-menu');
            $htmlBody.addClass('megamenu-open');

            if( !$(e.currentTarget).hasClass('active') ) {

                $dropLinks.removeClass('active');
                $mmItems.removeClass('active');

                $(e.currentTarget).addClass('active');
                $mmItems.filter('.'+currSlug).addClass('active');
                $megaMenu.find('.mm-innerwrap').css('height', $mmItems.filter('.'+currSlug).outerHeight());

            }
        }

    }).on('mouseleave', function(e) {

        mmCloser();

    });

    $mmItems.on('mouseenter', function(e) {

        clearTimeout(navTimerClose);

    }).on('mouseleave', function(e) {

        mmCloser();

    });
    // END DESKTOP MENU FUNCTIONS


    // MOBILE MENU FUNCTIONS
    $mobileTrigger.on('click', function(e) {
        if(navState == 'mobile') {

            if( ! $(e.currentTarget).hasClass('is-active') ) {

                let menuOffset = $('header').outerHeight() + 60;

                if( $('.notification-bar').length ) {
                    menuOffset += $('.notification-bar').outerHeight();
                }

                $(e.currentTarget).addClass('is-active');
                $htmlBody.addClass('mobile-nav-open');
                $mobMenu.css('max-height', 'calc(100vh - '+menuOffset+'px)');

            } else {

                $(e.currentTarget).removeClass('is-active');
                $body.removeClass('sub-nav-open');
                $htmlBody.removeClass('mobile-nav-open');
                $mobMenu.css('max-height', '0');

            }
        }
    });

    $mobSubTriggers.on('click', function(e) {
        const $cur      = $(e.currentTarget);
        let elHeight    = 0;

        if( ! $cur.hasClass('active') ) {

            $mobSubTriggers.removeClass('active').next().css('max-height', '0');
            $cur.next().find('li').each(function() {
                elHeight += $(this).outerHeight();
            });
            $cur.addClass('active');
            $cur.next().css('max-height', elHeight + 'px');

        } else {

            $cur.removeClass('active');
            $cur.next().css('max-height', '0');

        }

    });
    // END MOBILE MENU FUNCTIONS

    // SEARCH DROP DOWN FUNCTIONS
    $searchTrigger.on('click', function(e) {

        if( !$body.hasClass('search-open') ) {

            $body.addClass('search-open');

        } else {

            $body.removeClass('search-open');

        }

    });
    // END SEARCH DROP DOWN FUNCTIONS


    /**************************************************************/
    /****************** FOOTER MENU FUNCTIONALITY *****************/
    /**************************************************************/
    const $footerQLSubTriggers = $('footer .quick-link-cols-wrap .ql-col .ql-item > .drop-trigger');

    $footerQLSubTriggers.on('click', function(e) {
        const $cur      = $(e.currentTarget);
        let elHeight    = 0;

        if( ! $cur.hasClass('active') ) {

            $footerQLSubTriggers.removeClass('active').next().css({
                'max-height': '0',
                'padding-bottom': '0px'
            });
            $cur.next().find('li').each(function() {
                elHeight += $(this).outerHeight();
            });
            $cur.addClass('active');
            $cur.next().css({
                'max-height': elHeight + 'px',
                'padding-bottom': '1.5rem'
            });

        } else {

            $cur.removeClass('active');
            $cur.next().css({
                'max-height': elHeight + 'px',
                'padding-bottom': '0px'
            });

        }

    });
    // END FOOTER MENU FUNCTIONS


    // init NavState on page load
    dbc_SetNavState();

    // handle window resize events
    $(window).on('resize', function() {
        dbc_SetNavState();
    });
    /********************** END MENU FUNCTIONALITY ******************/

});
